import React from "react"
import { graphql } from "gatsby"
import Seo from "../components/SEO"
import { HeaderContainer } from "../components/HeaderContainer"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"
import { getCurrentLocale } from "../utils/getCurrentLocale"
import { FaInstagram, FaFacebook, FaLink } from "react-icons/fa"
import { LightgalleryItem } from "react-lightgallery";

const BigLogoContainer = styled.div`
  margin-left: 2rem;
  min-width: 10%;
`

const Perks = styled.div`
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  margin: -8px;
  & * {
    margin: 8px;
  }
`

const Perk = styled.div`
  display: inline-flex;
  justify-content: space-between;
  gap: 12px;
  flex: 1;
  margin: 8px 0;
  min-width: 350px;
  max-width: 800px;
`

const MainSectionContainer = styled.div`
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  
  margin: -8px;
  & * {
    margin: 4px;
  }
  margin-top: 36px;
`

const HighlightedImageContainer = styled.div`
  flex: 1;
  min-width: 300px;
`

const Description = styled.div`
  min-width: 300px;
  flex: 2;
`

const ParagraphHeader = styled.h3`
  font-style: italic;
`

const SocialIcon = styled.a`
  padding: 8px;
  padding-left: 0;
`

const GalleryContainer = styled.div`
  margin-top: 36px;
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 12px;
`

const GalleryImage = styled.div`
  height: 150px;
  width: 200px;
  overflow: hidden;
`

const WineryPage = ({ data }) => {
  const frontmatter = data.markdownRemark.frontmatter;
  const logo = getImage(frontmatter.logo)
  const featuredImage = getImage(frontmatter.featuredImage)
  const locale = getCurrentLocale();

  const highlightedPerks = {
    establishedIn: frontmatter.establishedIn,
    vineyardArea: frontmatter.vineyardArea,
    production: frontmatter.production,
    address: frontmatter.address,
    phone: frontmatter.phone,
    email: frontmatter.email,
  }

  const getPerkName = (key) => {
    switch (key) {
      case "establishedIn":
        return locale === "en" ? "Established in" : "Założona w"
      case "vineyardArea":
        return locale === "en" ? "Vineyard area" : "Obszar winnic"
      case "production":
        return locale === "en" ? "Production" : "Produkcja"
      case "address":
        return locale === "en" ? "Address" : "Adres"
      case "phone":
        return locale === "en" ? "Phone" : "tel."
      case "email":
        return locale === "en" ? "Email" : "email"
      default:
        throw Error(`Unknown perk key: ${key}`);
    }
  }

  const renderPerk = (key) => {
    if (key === "email") return <a
      style={{ textAlign: "right" }}
      href={`mailto:${highlightedPerks[key]}`}
      rel="noopener noreferrer"
    >
      {highlightedPerks[key]}
    </a>
    if (key === "phone") return <a
      href={`tel:${highlightedPerks[key]}`}
      rel="noopener noreferrer"
      style={{ textAlign: "right" }}
    >
      {highlightedPerks[key]}
    </a>
    else return <span
      style={{ textAlign: "right" }}
    >
      {highlightedPerks[key]}
    </span>
  }

  return (
    <>
      <Seo />
      <HeaderContainer style={{ fontStyle: "italic" }}>
        <h1>{frontmatter.title}</h1>
        <BigLogoContainer>
          <GatsbyImage image={logo} alt={"Wina Węgierskie Logo"}/>
        </BigLogoContainer>
      </HeaderContainer>

      <Perks>
      {
        Object.keys(highlightedPerks)
          .filter(key => highlightedPerks[key] !== undefined)
          .map(key => (<Perk><span>{getPerkName(key)}:</span>{renderPerk(key)}</Perk>))
      }
      </Perks>

      <MainSectionContainer>
        <HighlightedImageContainer>
          <GatsbyImage image={featuredImage} alt={frontmatter.title}/>
        </HighlightedImageContainer>
        <Description dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }} />
      </MainSectionContainer>

      {(frontmatter.whiteWines || frontmatter.redWines || frontmatter.roseWines) && (
        <ParagraphHeader>{locale === "en" ? "Wine types" : "Rodzaje win"}</ParagraphHeader>
      )}
      {frontmatter.whiteWines && <p><b>{locale === "en" ? "White wines" : "Wina białe"}: </b> {frontmatter.whiteWines}</p>}
      {frontmatter.redWines && <p><b>{locale === "en" ? "Red wines" : "Wina czerwone"}: </b> {frontmatter.redWines}</p>}
      {frontmatter.roseWines && <p><b>{locale === "en" ? "Rosé wines" : "Wina rosé"}: </b> {frontmatter.roseWines}</p>}
      {frontmatter.services && 
        <>
          <ParagraphHeader>{locale === "en" ? "Services" : "Usługi"}</ParagraphHeader>
          <p>{frontmatter.services}</p>
        </>
      }

      { (frontmatter.facebook || frontmatter.instagram || frontmatter.website) &&
        <p>
          { frontmatter.facebook &&
            <SocialIcon
              href={frontmatter.facebook}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaFacebook size={28} />
            </SocialIcon>
          }
          { frontmatter.instagram &&
            <SocialIcon
              href={frontmatter.instagram}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaInstagram size={28} />
            </SocialIcon>
          }
          { frontmatter.website &&
            <SocialIcon
              href={frontmatter.website.startsWith("http") ? frontmatter.website : `https://${frontmatter.website}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaLink size={28} />
            </SocialIcon>
          }
        </p>
      }
      

      {data?.galleryImages?.edges && <>
        <ParagraphHeader>{locale === "en" ? "Gallery" : "Galeria"}</ParagraphHeader>
        <GalleryContainer>
          {data.galleryImages.edges.map(image => getImage(image.node)).map(image => <GalleryImage>
            <LightgalleryItem src={image.images.fallback.src}>
              <GatsbyImage objectFit="fill" image={image} alt={frontmatter.title}/>
            </LightgalleryItem>
          </GalleryImage>
          )}

        </GalleryContainer>
      </>
      }
    </>
  )
}

export default WineryPage

export const wineryQuery = graphql`
query ($path: String!, $galleryPath: String!) {
  markdownRemark(fields: {path: {eq: $path}}) {
    html
    frontmatter {
      logo {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      path
      title
      establishedIn
      address
      vineyardArea
      description
      phone
      production
      email
      featuredImage {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      whiteWines
      redWines
      roseWines
      services
      facebook
      instagram
      website
      galleryPath
    }
  }
  galleryImages: allFile(
    filter: {
      absolutePath: { regex: $galleryPath }
    }
  ) {
    edges {
      node {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  }
}
`

